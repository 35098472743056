import { useTranslation } from 'react-i18next'
import Typography from '~/components/Typography'

export type CheckboxINeedAnInvoiceProps = {
  isDefaultChecked: boolean
  onChange: (checked: boolean) => void
}

const CheckboxINeedAnInvoice = ({
  isDefaultChecked,
  onChange
}: CheckboxINeedAnInvoiceProps) => {
  const { t } = useTranslation(['common'])

  const handleINeedAnInvoice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    onChange(checked)
  }

  return (
    <div className="flex justify-center gap-x-1">
      <input
        id="i-need-an-invoice"
        type="checkbox"
        onChange={handleINeedAnInvoice}
        defaultChecked={isDefaultChecked}
      />
      <Typography
        component="label"
        htmlFor="i-need-an-invoice"
        variant="mention"
      >
        {t('common:iNeedAnInvoice')}
      </Typography>
    </div>
  )
}

export default CheckboxINeedAnInvoice
